import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContainerBox from "../Container";
import ErrorAlert from "../errorAlert";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import { TranslatedDatePicker } from "../Util/translatedDatePicker";
import MuniReportClubs from "./MuniReportClubs";

import { isAdmin, isGedu, isParent, isGamer } from "../Util/authHelper";
import { formatDate } from "../Util/clubHelper";

import {
  invoicingGedus,
  getGeduInvoicingPeriods,
  getMuniClubsBetweenDates,
} from "../Hooks/report";

import { getCountries } from "../Hooks/country";
import { getActiveMunicipalities } from "../Hooks/municipality";

import { useTranslation } from "react-i18next";
import { set, setMinutes } from "date-fns";

export default function MuniReportMainView(params) {
  const { t } = useTranslation();

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [municipalities, setMunicipalities] = useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = useState("");
  const [selectedMunicipalityData, setSelectedMunicipalityData] = useState({});

  const [isLoadingInvoicingData, setIsLoadingInvoicingData] = useState(false);

  const [periodType, setPeriodType] = useState("currentMonth");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [yearList, setYearList] = React.useState([]);
  const [selectedYears, setSelectedYears] = useState();
  const [geduList, setGeduList] = React.useState([]);
  const [selectedGedu, setSelectedGedu] = useState();

  const [clubs, setClubs] = useState([]);

  const appUserRoles = params?.appAuthInfo?.appUserRoles;

  const {
    data: countryData,
    error: errorCountryData,
    isLoading: isLoadingCountryData,
    refetch: refetchCountryData,
  } = useQuery({
    queryKey: ["getCountries"],
    queryFn: () => getCountries(),
    enabled: true,
  });

  const {
    data: municipalityData,
    error: errorMunicipalityData,
    isLoading: isLoadingMunicipalityData,
    refetch: refetchMunicipalityData,
  } = useQuery({
    queryKey: ["getMunicipalities", selectedCountry.value],
    queryFn: () => getActiveMunicipalities(selectedCountry.value),
    enabled: true,
  });

  const {
    data: invoicingPeriods,
    error: errorInvoicingPeriods,
    isLoading: isLoadingInvoicingPeriods,
    refetch: refetchInvoicingPeriods,
  } = useQuery({
    queryKey: ["invoicingPeriods", selectedGedu],
    queryFn: () => getGeduInvoicingPeriods,
    enabled: false,
  });

  useEffect(() => {
    // Set the default dates for current month
    const { startDate, endDate } = getPreviousMonth();
    console.log(startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
    setPeriodType("previousMonth");
  }, []);

  useEffect(() => {
    if (countryData) {
      prepareCountries();
    }
    // eslint-disable-next-line
  }, [countryData]);

  useEffect(() => {
    if (municipalityData) {
      prepareMunicipalities();
    }
    // eslint-disable-next-line
  }, [municipalityData]);

  useEffect(() => {
    if (invoicingPeriods) {
      prepareInvoicingPeriods(true);
    }
    // eslint-disable-next-line
  }, [invoicingPeriods, selectedYears, selectedGedu]);

  const getCurrentMonth = () => {
    // create a struct with startDate and endDate for current month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  const getPreviousMonth = () => {
    // create a struct with startDate and endDate for previous month
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);

    return { startDate: firstDay, endDate: lastDay };
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);

    setPeriodType("custom"); // Change periodType to custom when the dates are touched
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);

    setPeriodType("custom"); // Change periodType to custom when the dates are touched
  };

  const prepareCountries = async () => {
    console.log(countryData);
    const countries = [];
    countryData.countries.map((country) => {
      countries.push({
        value: country.id,
        label: country.name,
      });
    });

    // sort the countries by name
    countries.sort((a, b) => (a.label > b.label ? 1 : -1));
    setCountries(countries);
  };

  const prepareMunicipalities = async () => {
    console.log(municipalityData);
    const municipalities = [];
    municipalityData.municipalities.map((municipality) => {
      municipalities.push({
        value: municipality.id,
        label: municipality.name,
      });
    });
    // sort municipalities by name
    municipalities.sort((a, b) => (a.label > b.label ? 1 : -1));
    setMunicipalities(municipalities);
  };

  const handleSelectedCountry = async (event) => {
    console.log(event);
    setSelectedCountry(event);
    refetchMunicipalityData(event.value);
  };

  const handleSelectedMunicipality = async (event) => {
    console.log(event);
    setSelectedMunicipality(event);
    //await fetchClubs(event);
  };

  const handleSubmit = async () => {
    await fetchClubs(selectedMunicipality);
  };

  const fetchClubs = async (municipality) => {
    setIsLoadingInvoicingData(true);
    const body = {
      municipalityId: municipality.value,
      startDate: formatDate(startDate, "YYYY-MM-DD"),
      endDate: formatDate(endDate, "YYYY-MM-DD"),
    };

    console.log(body);
    const response = await getMuniClubsBetweenDates(body);

    console.log(response);

    const clubs = response.data.clubs;
    const municipalityData = response.data.municipality;
    setSelectedMunicipalityData(municipalityData);
    setClubs(clubs);

    setIsLoadingInvoicingData(false);
  };

  const handleButtonClick = (period) => {
    setPeriodType(period);

    if (period === "currentMonth") {
      const { startDate, endDate } = getCurrentMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "previousMonth") {
      const { startDate, endDate } = getPreviousMonth();
      setStartDate(startDate);
      setEndDate(endDate);
    } else if (period === "custom") {
      // don't change the dates
    }
  };

  return (
    <>
      {errorCountryData && <ErrorAlert txt={errorCountryData} />}
      {errorMunicipalityData && <ErrorAlert txt={errorMunicipalityData} />}
      {(isLoadingCountryData || isLoadingMunicipalityData) && (
        <CircularProgress />
      )}
      {countries && countries.length > 0 && (
        // <div id="FilterDiv" style={{ marginBottom: 10 }}>
        <Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{ display: "block", m: 1, mb: 3 }}
          >
            <Button
              variant={
                periodType === "previousMonth" ? "contained" : "outlined"
              }
              onClick={() => handleButtonClick("previousMonth")}
            >
              {t("geduInvoicing.previousMonth")}
            </Button>
            <Button
              variant={periodType === "currentMonth" ? "contained" : "outlined"}
              onClick={() => handleButtonClick("currentMonth")}
            >
              {t("geduInvoicing.currentMonth")}
            </Button>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ display: "block", m: 1 }}>
            <TranslatedDatePicker
              fullWidth
              label={t("geduInvoicing.startDate")}
              value={startDate}
              onChange={(newValue) => handleStartDateChange(newValue)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TranslatedDatePicker
              fullWidth
              type="date"
              label={t("geduInvoicing.endDate")}
              value={endDate}
              onChange={(newValue) => handleEndDateChange(newValue)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>

          <Stack direction="row" spacing={2} sx={{ display: "block", m: 2 }}>
            <div
              style={{
                marginRight: "0.25em",
                marginBottom: "0.25em",
                maxWidth: 300,
                display: "inline-flex",
              }}
            >
              <Select
                name="geduFilter"
                options={countries}
                className="basic-multi-select"
                placeholder={t("muniReport.selectCountry")}
                value={selectedCountry}
                onChange={(event) => {
                  handleSelectedCountry(event);
                }}
                isSearchable={true}
                // isMulti
                multiple={false} // Set to false for single-select
                native={false} // Set to false to use the custom Select component
              />
            </div>

            {municipalities && municipalities.length > 0 && (
              <div
                style={{
                  marginRight: "0.25em",
                  marginBottom: "0.25em",
                  maxWidth: 300,
                  display: "inline-flex",
                }}
              >
                <Select
                  name="municipalityFilter"
                  options={municipalities}
                  className="basic-multi-select"
                  placeholder={t("muniReport.selectMunicipality")}
                  value={selectedMunicipality}
                  onChange={(event) => handleSelectedMunicipality(event)}
                  isSearchable={true}
                  // isMulti
                  multiple={false} // Set to false for single-select
                  native={false} // Set to false to use the custom Select component
                />
              </div>
            )}
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                !startDate ||
                !endDate ||
                !selectedCountry ||
                !selectedMunicipality
              }
            >
              {t("trusterReport.submit")}
            </Button>
            {/* {periods && (
            <div
              style={{
                marginRight: "0.25em",
                marginBottom: "0.25em",
                maxWidth: 300,
                display: "inline-flex",
              }}
            >
              <Select
                name="yearFilter"
                options={yearList}
                className="basic-multi-select"
                placeholder={t("geduInvoicing.filter_byYearPlaceholder")}
                value={selectedYears}
                onChange={handleSelectYear}
                isSearchable={true}
                isMulti
              />
            </div>
          )} */}
          </Stack>
        </Box>
        // </div>
      )}
      {isLoadingInvoicingData && <CircularProgress sx={{ m: 3 }} />}
      {!isLoadingInvoicingData && clubs && selectedMunicipalityData && (
        <MuniReportClubs
          clubs={clubs}
          refetch={fetchClubs}
          municipality={selectedMunicipality}
          municipalityData={selectedMunicipalityData}
        />
      )}
    </>
  );
}
