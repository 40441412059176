import React, { useEffect, useState } from "react";
import "./purchase.css";
import ContainerBox from "../Container";
import { Button, Modal, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import jwt_decode from "jwt-decode";
import { useMutation } from "react-query";
import { getGamers, sendForm, checkValid } from "../Hooks/purchase";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import SlotSelection from "./selectSlot";
import FinishSlotSelection from "./finishSlotSelection";
import SOGLogo from "../logo.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CakeIcon from "@mui/icons-material/Cake";
import Checkbox from "@mui/material/Checkbox";
import SlotSelectionPsw from "./newPsw";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import CircularProgress from "@mui/material/CircularProgress";

import { useTranslation, Trans } from "react-i18next";
import i18n from "../i18n";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #faa901",
  boxShadow: 24,
  p: 4,
};

export default function Purchase() {
  const { t } = useTranslation();
  const [skipped, setSkipped] = React.useState(new Set());
  const [activeStep, setActiveStep] = React.useState(0);
  const [newPsw, setNewPsw] = useState("");
  const [state, setState] = React.useState({
    open: true,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [disableNext, setDisableNext] = useState(true);
  const [openGamer, setOpenGamer] = useState(false);
  const [gamers, setGamers] = useState([]);
  const [jwt, setJwt] = useState(-1);
  const [showError, setShowError] = useState(false);
  const { mutate: getGamersList } = useMutation(getGamers);
  const { mutate: checkPrecondition } = useMutation(checkValid);
  const { mutate: sendFormInfo } = useMutation(sendForm);
  const [options, setOptions] = useState([]);
  const [elementGamer, setElementGamer] = useState();
  const [preconditionLoading, setPreconditionLoading] = useState(true);
  const [precondition, setPrecondition] = useState(false);

  // we do not need pw setting anymore since parents log in to
  // sogga mobile through one-time password, so we can skip this step
  const [preconditionUserOk, setPreconditionUserOk] = useState(true);

  const [preconditionError, setPreconditionError] = useState("");
  const [gamerFirstName, setGamerFirstName] = useState("");
  const [gamerLastName, setGamerLastName] = useState("");
  const [gamerBirthDate, setGamerBirthDate] = useState(new Date());
  const [gamerCreated, setGamerCreated] = useState(false);
  const [infoSended, setInfoSended] = useState(false);
  const [infoSendedError, setInfoSendedError] = useState("");

  const [steps, setSteps] = useState([
    t("slotSelection.steps.firstStep.name"),
    t("slotSelection.steps.secondStep.name"),
    t("slotSelection.steps.thirdStep.name"),
  ]);
  const sendInfo = () => {
    console.log("entro");
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      console.log(sessionStorage.getItem("gamer"));
      let bodyObj = {
        token: urlParams.get("p"),
        password: newPsw !== "" ? newPsw : undefined,
      };

      if (jwt.slotSKUClubId) {
        bodyObj.clubs = [jwt.slotSKUClubId];
      } else {
        bodyObj.clubs =
          jwt.plan.seatCount === 0
            ? ["362921201439344640"]
            : JSON.parse(sessionStorage.getItem("slots")).map(
                (slot) => slot.id
              );
      }

      if (!JSON.parse(sessionStorage.getItem("gamer")).id) {
        bodyObj.newGamer = {
          firstName:
            JSON.parse(sessionStorage.getItem("gamer")).firstName || undefined,
          lastName:
            JSON.parse(sessionStorage.getItem("gamer")).lastName || undefined,
          birthdate:
            JSON.parse(sessionStorage.getItem("gamer")).birthdate || undefined,
          ageFlexConsent: true,
        };
      } else {
        bodyObj.gamerId = JSON.parse(sessionStorage.getItem("gamer")).id || "";
      }

      console.log(bodyObj);
      sendFormInfo(bodyObj, {
        onSuccess: (e) => {
          console.log(e);
          setInfoSended(true);
        },
        onError: (e) => {
          console.log(e.response.data.message);

          setInfoSendedError(
            e.response.data.message || "Error, please try again"
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (jwt.plan.seatCount === 0 || jwt.slotSKUClubId) {
      if (activeStep === 2) setActiveStep(3);
      if (activeStep !== 2) setActiveStep(2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (jwt.plan.seatCount === 0 || jwt.slotSKUClubId) {
      setActiveStep((prevActiveStep) => 0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const handlePswChange = (password) => {
    setNewPsw(password);
    setPreconditionUserOk(true);
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleOpen = () => setOpenGamer(true);
  const handleCloseGamer = () => setOpenGamer(false);

  useEffect(() => {
    try {
      //First we clear the sessionStorage
      sessionStorage.setItem("gamer", "{}");
      sessionStorage.setItem("slots", "[]");
      if (!sessionStorage.getItem("psw")) sessionStorage.setItem("psw", "-1");

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      urlParams.get("p");
      const token = jwt_decode(urlParams.get("p"));
      let comodin = steps;

      //if(token.plan.seatCount === 0) setSteps([comodin[0], comodin[2]])
      //Add Language
      console.log(token.plan.locale);
      i18n.changeLanguage(token.plan.locale);
      //Check precondition
      checkPrecondition(
        { token: urlParams.get("p") },
        {
          onSuccess: (e) => {
            //This was created without the actual API call, so once you created ther API call, check this
            console.log(e.status);
            if (e.status === 200) {
              setPreconditionUserOk(true);
            }
            setPrecondition(true);
            setPreconditionLoading(false);
          },
          onError: (error) => {
            setPreconditionError(error.response.data.message || "Error");
            setPreconditionLoading(false);
          },
        }
      );
      //Get the JWT from URL

      setJwt(token);
      console.log(token);

      //Get Gamers (changed to be based on email, not userId)
      if (token.email && token.email.length > 1) {
        getGamersList(
          { token: urlParams.get("p") },
          {
            onSuccess: (e) => {
              console.log(e);
              setOptions(e.gamers);
            },
          }
        );
      }
    } catch (error) {
      setShowError(true);
    }
  }, [newPsw, gamers]);

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  const handleSubmit = async (e) => {
    //To create the new User
    e.preventDefault();
    let comodin = [];

    comodin.push({
      firstName: gamerFirstName,
      lastName: gamerLastName,
      birthdate: gamerBirthDate,
    });

    setGamers(comodin);
    if (elementGamer) elementGamer.classList.remove("selected-gamer");
    setElementGamer(null);
    if (activeStep === 0) setDisableNext(false);
    sessionStorage.setItem("gamer", JSON.stringify(comodin[0]));
    setGamerCreated(true);
    handleCloseGamer();
  };
  const styleHtml = () => {
    document.getElementsByTagName("html")[0].style.overflow = "auto";
  };

  /*
    Here we have the steps, so basically "activeStep" variable is the one who 
    determinates the actual step we are (that are defined in the "steps" variable)
  
  */
  return (
    <ContainerBox className="slot-page">
      {!preconditionLoading && (
        <div className="slot-page">
          {
            //In case the token is not valid in someway
            showError ?? (
              <div className="purchase-page">
                <p>
                  Bad token, you need a token with the information of your
                  purchase in order to complete the order
                </p>
              </div>
            )
          }
          <img src={SOGLogo} alt="logo" className="logo-slot" />
          {!precondition && (
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              autoHideDuration={6000}
              key={vertical + horizontal}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {preconditionError}
              </Alert>
            </Snackbar>
          )}
          {!preconditionUserOk && sessionStorage.getItem("psw") === "-1" && (
            <SlotSelectionPsw newPswChange={handlePswChange} />
          )}
          {
            //If there is no error, the JWT exits and the precondition is valid, we show the data

            !showError &&
              jwt !== -1 &&
              precondition &&
              (sessionStorage.getItem("psw") !== "-1" ||
                preconditionUserOk) && (
                <div className="purchase-page">
                  <Stepper activeStep={activeStep}>
                    {
                      //To show the Steps on the top of the page
                      steps.map((label, index) => {
                        if (
                          index === 1 &&
                          (jwt.plan.seatCount === 0 || jwt.slotSKUClubId)
                        ) {
                        } else {
                          const stepProps = {};
                          const labelProps = {};

                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        }
                      })
                    }
                  </Stepper>

                  {
                    //If the user has finish al the Steps
                    activeStep === steps.length ||
                    (jwt.plan.seatCount === 0 && activeStep === 1) ? (
                      <React.Fragment>
                        {infoSended && (
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            <h3>
                              {t(
                                "slotSelection.steps.fourthStep.success.title"
                              )}
                            </h3>
                            <p>
                              {t("slotSelection.steps.fourthStep.success.p1")}
                            </p>
                            <p>
                              {t("slotSelection.steps.fourthStep.success.p2")}
                            </p>
                            <p>
                              {t("slotSelection.steps.fourthStep.success.p3")}
                            </p>
                            <br></br>
                            <p>
                              {t(
                                "slotSelection.steps.fourthStep.success.greeting"
                              )}
                            </p>
                            <br></br>
                            <p>
                              {t("slotSelection.steps.fourthStep.success.ps")}
                            </p>
                          </Typography>
                        )}
                        {infoSendedError !== "" && (
                          <Typography sx={{ mt: 2, mb: 1, color: "#ff0033" }}>
                            <h3>⚠ {infoSendedError} ⚠</h3>
                            <p>
                              {t(
                                "slotSelection.steps.fourthStep.error.tryAgain"
                              )}
                            </p>
                            <Button
                              variant="contained"
                              color="secondary"
                              type="submit"
                              onClick={() => setActiveStep(0)}
                            >
                              {t(
                                "slotSelection.steps.fourthStep.error.goBackBtn"
                              )}
                            </Button>
                          </Typography>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {
                          //First Step
                          activeStep === 0 && (
                            <>
                              {styleHtml()}
                              <br></br>
                              <p>
                                {" "}
                                {t("slotSelection.steps.firstStep.sayHi", {
                                  firstName: jwt.firstName,
                                })}{" "}
                              </p>
                              {/* <p>{jwt.email}</p> */}
                              <h3>
                                {t("slotSelection.steps.firstStep.welcomeText")}
                              </h3>
                              <br></br>

                              {jwt.slotSKUClubId && (
                                <p style={{ textAlign: "left" }}>
                                  {t(
                                    "slotSelection.steps.firstStep.infoPurchase_pre"
                                  ) + " "}
                                  <span className="slot-txt">
                                    {jwt.plan.name}
                                  </span>
                                </p>
                              )}
                              {!jwt.slotSKUClubId && (
                                <p style={{ textAlign: "left" }}>
                                  {t(
                                    "slotSelection.steps.firstStep.infoPurchase"
                                  ) + " "}
                                  <span className="slot-txt">
                                    {jwt.plan.name}
                                  </span>
                                </p>
                              )}
                              <p>
                                {t("slotSelection.steps.firstStep.infoGamers")}
                              </p>

                              <br></br>
                              {options.length >= 0 && (
                                <p style={{ textAlign: "left" }}>
                                  <Trans
                                    sx={{
                                      listStyleType: "disc",
                                      textAlign: "left",
                                      marginBottom: "1rem",
                                    }}
                                    i18nKey="slotSelection.steps.firstStep.addGamerInfo"
                                    components={{
                                      ul: <ul />,
                                      li: <li />,
                                    }}
                                  >
                                    infoGamers
                                  </Trans>

                                  {/* {t(
                                    "slotSelection.steps.firstStep.addGamerInfo"
                                  )} */}
                                </p>
                              )}

                              <div className="select-gamer">
                                {
                                  //Show the list of actual existing gamers
                                  (options.length > 0 || gamers.length > 0) && (
                                    <>
                                      {options.map((gamerCard, i) => (
                                        <div
                                          className={`card-gamer ${
                                            gamers.length > 0
                                              ? "disable-card-hover"
                                              : ""
                                          } ${
                                            JSON.parse(
                                              sessionStorage.getItem("gamer")
                                            ) &&
                                            gamers.length <= 0 &&
                                            JSON.parse(
                                              sessionStorage.getItem("gamer")
                                            ).id === gamerCard.id
                                              ? "selected-gamer"
                                              : ""
                                          }`}
                                          key={gamerCard.firstName + i}
                                          id={gamerCard.firstName + i}
                                          onClick={(e) => {
                                            if (
                                              document
                                                .getElementById(
                                                  gamerCard.firstName + i
                                                )
                                                .classList.contains(
                                                  "selected-gamer"
                                                )
                                            ) {
                                              document
                                                .getElementById(
                                                  gamerCard.firstName + i
                                                )
                                                .classList.remove(
                                                  "selected-gamer"
                                                );
                                            } else {
                                              if (elementGamer === null) {
                                                document
                                                  .getElementById(
                                                    gamerCard.firstName + i
                                                  )
                                                  .classList.add(
                                                    "selected-gamer"
                                                  );
                                                if (activeStep === 0)
                                                  setDisableNext(false);
                                                sessionStorage.setItem(
                                                  "gamer",
                                                  JSON.stringify(gamerCard)
                                                );
                                                setElementGamer(
                                                  document.getElementById(
                                                    gamerCard.firstName + i
                                                  )
                                                );
                                              } else {
                                                if (elementGamer)
                                                  elementGamer.classList.remove(
                                                    "selected-gamer"
                                                  );
                                                document
                                                  .getElementById(
                                                    gamerCard.firstName + i
                                                  )
                                                  .classList.add(
                                                    "selected-gamer"
                                                  );
                                                if (activeStep === 0)
                                                  setDisableNext(false);
                                                sessionStorage.setItem(
                                                  "gamer",
                                                  JSON.stringify(gamerCard)
                                                );
                                                setElementGamer(
                                                  document.getElementById(
                                                    gamerCard.firstName + i
                                                  )
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          <Card
                                            sx={{
                                              width: "100%",
                                              textAlign: "left",
                                            }}
                                            className={`${
                                              gamers.length > 0
                                                ? "disable-card"
                                                : ""
                                            }`}
                                            key={gamerCard.username}
                                          >
                                            <CardContent>
                                              <Typography
                                                sx={{ fontSize: 14 }}
                                                color="text.secondary"
                                                gutterBottom
                                              >
                                                {(
                                                  <>
                                                    <CakeIcon fontSize="small" />{" "}
                                                    <span className="date-gamer-slot">
                                                      {gamerCard.birthdate}
                                                    </span>
                                                  </>
                                                ) || "Gamer Data"}
                                              </Typography>
                                              <Typography
                                                variant="h5"
                                                component="div"
                                              >
                                                {gamerCard.firstName}
                                              </Typography>
                                              <Typography variant="body2">
                                                {gamerCard.lastName}
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </div>
                                      ))}

                                      {gamers.length > 0 &&
                                        gamers.map((gamer, i) => {
                                          if (elementGamer)
                                            elementGamer.classList.remove(
                                              "selected-gamer"
                                            );
                                          // if(activeStep === 0) setDisableNext(false)
                                          sessionStorage.setItem(
                                            "gamer",
                                            JSON.stringify(gamer)
                                          );

                                          return (
                                            <div
                                              className="card-gamer selected-gamer"
                                              key={gamer.firstName + i}
                                              id={gamer.firstName + i}
                                              onClick={(e) => {}}
                                            >
                                              <Card
                                                sx={{
                                                  width: "100%",
                                                  textAlign: "left",
                                                }}
                                                key={gamer.username}
                                              >
                                                <CardContent>
                                                  <Typography
                                                    sx={{ fontSize: 14 }}
                                                    color="text.secondary"
                                                    gutterBottom
                                                  >
                                                    {(
                                                      <>
                                                        <CakeIcon fontSize="small" />{" "}
                                                        <span className="date-gamer-slot">
                                                          {gamer.birthdate}
                                                        </span>
                                                      </>
                                                    ) || "Gamer Data"}
                                                  </Typography>
                                                  <Typography
                                                    variant="h5"
                                                    component="div"
                                                  >
                                                    {gamer.firstName}
                                                  </Typography>
                                                  <Typography variant="body2">
                                                    {gamer.lastName}
                                                    <span className="icons-new-gamer">
                                                      <EditIcon
                                                        fontSize="small"
                                                        onClick={handleOpen}
                                                      />
                                                        
                                                      <DeleteIcon
                                                        fontSize="small"
                                                        onClick={() => {
                                                          setGamerBirthDate(
                                                            new Date()
                                                          );
                                                          setGamerFirstName("");
                                                          setGamerLastName("");
                                                          setGamers([]);
                                                          if (activeStep === 0)
                                                            setDisableNext(
                                                              true
                                                            );
                                                        }}
                                                      />
                                                    </span>
                                                  </Typography>
                                                </CardContent>
                                              </Card>
                                            </div>
                                          );
                                        })}
                                    </>
                                  )
                                }
                                {gamers.length === 0 && (
                                  <div
                                    className="card-gamer"
                                    onClick={handleOpen}
                                  >
                                    <Card
                                      sx={{
                                        width: "100%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <CardContent
                                        sx={{
                                          height: "85px",
                                          display: "flex",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {t(
                                          "slotSelection.steps.firstStep.addNewGamerButtonTitle"
                                        )}
                                        <AddCircleIcon fontSize={"large"} />
                                      </CardContent>
                                    </Card>
                                  </div>
                                )}
                              </div>

                              {
                                //Modal to create a new gamer
                              }
                              <Modal
                                open={openGamer}
                                onClose={handleCloseGamer}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box
                                  sx={style}
                                  component="form"
                                  onSubmit={handleSubmit}
                                >
                                  <TextField
                                    required
                                    id="gamer-firstName"
                                    label={t(
                                      "slotSelection.steps.firstStep.modalNewGamer.firstName"
                                    )}
                                    InputLabelProps={{ shrink: true }}
                                    value={gamerFirstName}
                                    onChange={(e) => {
                                      setGamerFirstName(e.target.value);
                                    }}
                                    sx={{ m: 1, width: "100%" }}
                                    autoComplete="off"
                                  />
                                  <TextField
                                    required
                                    id="gamer-lastName"
                                    label={t(
                                      "slotSelection.steps.firstStep.modalNewGamer.lastName"
                                    )}
                                    value={gamerLastName}
                                    onChange={(e) => {
                                      setGamerLastName(e.target.value);
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ m: 1, width: "100%" }}
                                    autoComplete="off"
                                  />
                                  <TextField
                                    required
                                    id="gamer-birth"
                                    label={t(
                                      "slotSelection.steps.firstStep.modalNewGamer.birthDate"
                                    )}
                                    value={gamerBirthDate}
                                    onChange={(e) => {
                                      setGamerBirthDate(e.target.value);
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ m: 1, width: "100%" }}
                                    type="date"
                                  />

                                  {getAge(gamerBirthDate) < 16 && (
                                    <>
                                      <Checkbox required />
                                      <span className="consent-age-slot">
                                        {t(
                                          "slotSelection.steps.firstStep.modalNewGamer.consentAge"
                                        )}{" "}
                                      </span>
                                      <br></br>
                                    </>
                                  )}

                                  <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ left: "35%", marginTop: "1rem" }}
                                    type="submit"
                                  >
                                    {gamers.length > 0
                                      ? t(
                                          "slotSelection.steps.firstStep.modalNewGamer.editGamerBtn"
                                        )
                                      : t(
                                          "slotSelection.steps.firstStep.modalNewGamer.createGamerBtn"
                                        )}{" "}
                                  </Button>
                                </Box>
                              </Modal>
                            </>
                          )
                        }

                        {
                          //If we go to the next step (selecting a slot)
                          activeStep === 1 && jwt.plan.seatCount !== 0 && (
                            <>
                              <SlotSelection token={jwt} />
                            </>
                          )
                        }
                        {
                          //If the user clicks on the last step (confirmation data)
                          activeStep === 2 && (
                            <>
                              <FinishSlotSelection token={jwt} />
                            </>
                          )
                        }

                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          {activeStep !== 0 && (
                            <Button
                              color="inherit"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                              className="btn-slot"
                            >
                              <ArrowBackIcon />
                              <span className="btn-txt-slot">
                                {t("slotSelection.steps.backBtn")}
                              </span>
                            </Button>
                          )}
                          <Box sx={{ flex: "1 1 auto" }} />
                          <Button
                            id="next-btn"
                            className={`btn-slot ${
                              !disableNext ? "" : "disable-next"
                            } ${
                              activeStep === 1
                                ? JSON.parse(sessionStorage.getItem("slots")) &&
                                  !JSON.parse(sessionStorage.getItem("slots"))
                                    .length > 0
                                  ? "disable-next"
                                  : ""
                                : ""
                            }`}
                            onClick={() => {
                              if (!disableNext) {
                                if (activeStep === 1) {
                                  if (
                                    JSON.parse(
                                      sessionStorage.getItem("slots")
                                    ) &&
                                    JSON.parse(sessionStorage.getItem("slots"))
                                      .length > 0
                                  ) {
                                    handleNext();
                                    //if (jwt.plan.seatCount === 0) handleNext();
                                    if (activeStep === 2) sendInfo();
                                  } else {
                                    console.log("aaa");
                                  }
                                } else {
                                  handleNext();

                                  if (activeStep === 2) sendInfo();
                                }
                              }
                            }}
                            disabled={disableNext}
                          >
                            <span className="btn-txt-slot">
                              {activeStep === steps.length - 1
                                ? t("slotSelection.steps.finishBtn")
                                : t("slotSelection.steps.nextBtn")}
                            </span>

                            <ArrowForwardIcon />
                          </Button>
                        </Box>
                      </React.Fragment>
                    )
                  }
                </div>
              )
          }
        </div>
      )}

      {preconditionLoading && <CircularProgress />}
    </ContainerBox>
  );
}
