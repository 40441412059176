import axios from "axios";
import { API } from "./API";

export const getMunicipalities = async (params) => {
  console.log(params);

  let id = params && params.queryKey ? params.queryKey[1] : params;
  if (!id) return;

  const { data } = await axios.get(`${API}/municipalities/?country=${id}`, {
    withCredentials: true,
  });
  return data.data;
};

export const getActiveMunicipalities = async (params) => {
  console.log(params);

  let id = params && params.queryKey ? params.queryKey[1] : params;
  if (!id) return;

  const { data } = await axios.get(
    `${API}/municipalities/active/?country=${id}`,
    {
      withCredentials: true,
    }
  );
  return data.data;
};

export const getAllMunicipalities = async () => {
  const { data } = await axios.get(`${API}/municipalities/`, {
    withCredentials: true,
  });
  return data.data;
};

export const createMunicipality = async (body) => {
  const { data } = await axios.post(`${API}/municipalities/create`, body, {
    withCredentials: true,
  });
  return data;
};
export const editMunicipality = async (body) => {
  const { data } = await axios.put(`${API}/municipalities/${body.id}`, body, {
    withCredentials: true,
  });
  return data;
};

export const deleteMunicipality = async (id) => {
  const { data } = await axios.delete(`${API}/municipalities/${id}`, {
    withCredentials: true,
  });
  return data;
};

export const addParticipationManual = async (body) => {
  const { data } = await axios.post(
    `${API}/registration/addParticipationManual`,
    body,
    {
      withCredentials: true,
    }
  );

  return data;
};

export const setInvoicingData = async (body) => {
  // console.log("SET INVOICING DATA");
  // console.log(body);

  const { data } = await axios.put(
    `${API}/municipalities/invoicingdata/${body.id}`,
    body.fields,
    {
      withCredentials: true,
    }
  );

  return data;
};
