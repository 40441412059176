import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useQuery } from "react-query";

import { getCodeDomainsByNames } from "../Hooks/codes";

const ClubGeneralInfoEditor = ({ data, onSave, isMuni }) => {
  const { t } = useTranslation();

  const [editedData, setEditedData] = useState(data);

  const [isOpen, setIsOpen] = useState(false);

  const { data: codesData } = useQuery(["languagesAndStatuses"], () =>
    getCodeDomainsByNames(["CLUB_LANGUAGE", "CLUB_STATUS"])
  );

  if (codesData) {
    codesData.sort((a, b) => a.name.localeCompare(b.name));
  }

  const languages = codesData ? codesData[0].codes : [];
  const statuses = codesData ? codesData[1].codes : [];

  const hasChanges = Object.keys(data).some((key) =>
    editedData[key]?.id
      ? editedData[key].id !== data[key].id
      : editedData[key] !== data[key]
  );

  const handleSave = () => {
    onSave(editedData);
    setIsOpen(false);
  };

  const handleClose = () => {
    setEditedData(data);
    setIsOpen(false);
  };

  const handleStatusChange = (e) => {
    setEditedData({
      ...editedData,
      status: statuses.find((status) => status.id === e.target.value),
    });
  };

  const handleSKUChange = (e) => {
    setEditedData({
      ...editedData,
      sku: e.target.value,
    });
  };

  const handleMaxParticipantsChange = (e) => {
    setEditedData({
      ...editedData,
      maxParticipants: e.target.value,
    });
  };

  const handleNameChange = (e) => {
    setEditedData({
      ...editedData,
      name: e.target.value,
    });
  };

  const handleLanguageChange = (e) => {
    setEditedData({
      ...editedData,
      language: languages.find((lang) => lang.id === e.target.value),
    });
  };

  const handleRegistrationChange = (e) => {
    setEditedData({
      ...editedData,
      registration: e.target.value,
    });
  };

  const handleDiscordRoleIdChange = (e) => {
    setEditedData({
      ...editedData,
      discordRoleId: e.target.value,
    });
  };

  const handleDiscordGuildIdChange = (e) => {
    setEditedData({
      ...editedData,
      discordGuildId: e.target.value,
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ m: 1 }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("clubData.clubGeneralInfoEditor")}
      </Button>
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>{t("clubData.clubGeneralInfoEditor")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>{t("clubData.status")}</InputLabel>
            <Select
              value={editedData?.status?.id ?? ""}
              onChange={handleStatusChange}
              label={t("clubData.status")}
            >
              {statuses.map((status) => (
                <MenuItem key={status.id} value={status.id}>
                  {t("code." + status.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            value={editedData.name}
            onChange={handleNameChange}
            label={t("clubData.clubName")}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            value={editedData.sku}
            onChange={handleSKUChange}
            label={t("clubData.sku")}
            sx={{ marginTop: 2 }}
          />
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>{t("clubData.language")}</InputLabel>
            <Select
              value={editedData?.language?.id ?? ""}
              onChange={handleLanguageChange}
              label={t("clubData.language")}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.id} value={lang.id}>
                  {t("code." + lang.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isMuni && (
            <TextField
              fullWidth
              value={editedData.maxParticipants}
              onChange={handleMaxParticipantsChange}
              label={t("clubData.maxParticipants")}
              sx={{ marginTop: 2 }}
            />
          )}
          {isMuni && (
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel>{t("clubData.registrationEnabled")}</InputLabel>
              <Select
                value={editedData.registration}
                onChange={handleRegistrationChange}
                label={t("clubData.registrationEnabled")}
              >
                <MenuItem key={true} value={true}>
                  {t("clubData.registrationActive")}
                </MenuItem>
                <MenuItem key={false} value={false}>
                  {t("clubData.registrationInactive")}
                </MenuItem>
              </Select>
            </FormControl>
          )}
          <TextField
            fullWidth
            value={editedData.discordRoleId}
            onChange={handleDiscordRoleIdChange}
            label={t("clubData.discordRoleName")}
            sx={{ marginTop: 2 }}
          />
          {editedData.discordRoleId && (
            <TextField
              fullWidth
              value={editedData.discordGuildId}
              onChange={handleDiscordGuildIdChange}
              label={t("clubData.discordGuildName")}
              sx={{ marginTop: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("general.close")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!hasChanges}
          >
            {t("general.dialog.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClubGeneralInfoEditor;
