import React from "react";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Paper,
  Box,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenIcon from "@mui/icons-material/OpenInNew";
import Alert from "@mui/material/Alert";

import ReceiptIcon from "@mui/icons-material/Receipt";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import { prepareClubName } from "../Util/clubHelper";
import { formatDate, prepareTime } from "../Util/clubHelper";
import { setMuniInvoicingStatuses } from "../Hooks/report";

import { useTranslation } from "react-i18next";
import { use } from "i18next";

const MuniReportClubs = ({ municipalities, refetch, country, currency }) => {
  const { t } = useTranslation();

  const [municipalityData, setMunicipalityData] = useState([]);
  const [totalInvoicingPerPaymentTerm, setTotalInvoicingPerPaymentTerm] =
    useState({});
  const [totals, setTotals] = useState({});
  const [clubHeader, setClubHeader] = useState("");
  const [isSettingStatus, setIsSettingStatus] = useState(false);
  const [statusSettingError, setStatusSettingError] = useState(false);
  const [totalNumberOfClubs, setTotalNumberOfClubs] = useState(0);
  const [municipalityIsEstimate, setMunicipalityIsEstimate] = useState(false);

  useEffect(() => {
    if (municipalities) {
      prepareData(municipalities);
    }
  }, [municipalities]);

  const invoicingStatusMappings = {
    Estimated: {
      backgroundColor: "#9E9E9E", // "#EBEBEB", // gray
      color: "white",
    },
    Approved: {
      backgroundColor: "#4DB3F5", // blue-ish
      color: "white",
    },
    Invoiced: {
      backgroundColor: "#6AC66B", // green-ish
      color: "white",
    },
    Undefined: {
      backgroundColor: "#EBEBEB", //  light grey, with black text
      color: "black",
    },
  };

  const dayOrder = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };

  const prepareData = (clubs) => {
    const muniList = [];

    console.log("prepareData");
    console.log(country);
    console.log(municipalities);

    let totalInvoicing = 0.0;
    let totalCost = 0.0;
    const invoicingPerPaymentTerm = {};

    // loop through the municipalities
    municipalities.map((municipality) => {
      const clubs = [];

      const municipalityData = {
        id: municipality.id,
        name: municipality.name,
        paymentTerm: municipality.invoicingInformation?.paymentTerm,
        totalIncome: municipality.totalIncome,
        totalCost: municipality.totalCost,
        invoicingStatus: municipality.invoicingStatus
          ? municipality.invoicingStatus
          : "Undefined",
      };

      for (const club of municipality.clubs) {
        const clubLongName = prepareClubName(club);
        const clubData = {
          id: club.id,
          longName: clubLongName,
          name: club.name,
          school: club.school,
          numberOfLessons: club.numberOfLessons,
          totalIncome: club.totalIncome,
          totalCost: club.totalCost,
          invoicingStatus: club.invoicingStatus
            ? club.invoicingStatus
            : "Undefined",
        };

        clubs.push(clubData);
      }

      municipalityData.clubs = clubs;

      // check if totalIncome is a number an add it to the total
      if (
        !isNaN(municipality.totalIncome) &&
        municipality.totalIncome !== null
      ) {
        totalInvoicing += parseFloat(municipality.totalIncome);

        const paymentTerm = municipality.invoicingInformation?.paymentTerm
          ? municipality.invoicingInformation?.paymentTerm
          : "Undefined";
        // store invoicing per payment term
        if (invoicingPerPaymentTerm[paymentTerm]) {
          invoicingPerPaymentTerm[paymentTerm] += parseFloat(
            municipality.totalIncome
          );
        } else {
          invoicingPerPaymentTerm[paymentTerm] = parseFloat(
            municipality.totalIncome
          );
        }
      }

      // check if totalCost is a number an add it to the total
      if (!isNaN(municipality.totalCost) && municipality.totalCost !== null) {
        totalCost += parseFloat(municipality.totalCost);
      }

      muniList.push(municipalityData);
    });

    // sort municipality list by name
    muniList.sort((a, b) => (a.name > b.name ? 1 : -1));

    setMunicipalityData(muniList);
    setTotalInvoicingPerPaymentTerm(invoicingPerPaymentTerm);

    console.log("totalInvoice: " + totalInvoicing + " totalCost: " + totalCost);

    const localTotals = {
      flag: true,
      totalInvoicing: totalInvoicing,
      totalCost: totalCost,
    };

    setTotals(localTotals);
  };

  const getInvoicingStatusColor = (status) => {
    if (invoicingStatusMappings[status])
      return invoicingStatusMappings[status].color;

    return invoicingStatusMappings["Undefined"].color;
  };

  const getInvoicingStatusBackgroundColor = (status) => {
    if (invoicingStatusMappings[status])
      return invoicingStatusMappings[status].backgroundColor;

    return invoicingStatusMappings["Undefined"].backgroundColor;
  };

  const copyToClipboard = (text) => {
    // Create a temporary input element and set its value to the text to copy
    const tempInput = document.createElement("input");
    tempInput.value = text;

    // Append the input to the DOM
    document.body.appendChild(tempInput);

    // Select the text in the input
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
  };

  const openMunicipality = (municipalityId) => {
    const newWindow = window.open(`/club/?id=${municipalityId}`, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const openClub = (clubid) => {
    const newWindow = window.open(`/club/?id=${clubid}`, "_blank");
    //if (newWindow) newWindow.focus();
  };

  const getInvoicingStringWithVAT = (amount) => {
    return (
      (amount * 1.255).toFixed(2) + // Updated from 1.24 to 1.255
      " " +
      t("code." + currency.name) +
      " " +
      t("muniReport.includingVAT")
    );
  };

  const getInvoicingString = (amount) => {
    return amount.toFixed(2) + " " + t("code." + currency.name);
  };

  const getInvoicingAmountAndAmountWithVAT = (amount) => {
    return (
      getInvoicingString(amount) + " / " + getInvoicingStringWithVAT(amount)
    );
  };

  return (
    <div>
      {municipalityData.length > 0 && (
        <>
          <List>
            <ListItem key={"muni" + municipalityData.id}>
              <ListItemText
                primary={`${t("muniReport.totalIncome")}`}
                secondary={
                  <Stack direction="column" spacing={0}>
                    <Typography key={"totalInvoicing" + municipalityData.id}>
                      {totals.totalInvoicing > 0
                        ? totals.totalInvoicing.toFixed(2) +
                          " " +
                          t("code." + currency.name)
                        : "-"}
                    </Typography>
                    <Typography key={"totalInvoicingVAT" + municipalityData.id}>
                      {totals.totalInvoicing > 0
                        ? (totals.totalInvoicing * 1.255).toFixed(2) + // Updated from 1.24 to 1.255
                          " " +
                          t("code." + currency.name) +
                          " " +
                          t("muniReport.includingVAT")
                        : "-"}
                    </Typography>
                    <Typography>
                      {t("muniReport.numberOfMunicipalities")}:{" "}
                      {municipalityData?.length}
                    </Typography>
                  </Stack>
                }
              />
              <ListItemText
                primary={`${t("muniReport.totalCost")}`}
                secondary={
                  <Stack direction="column" spacing={0}>
                    <Typography>
                      {totals.totalCost > 0
                        ? totals.totalCost.toFixed(2) +
                          " " +
                          t("code." + currency.name)
                        : "-"}
                    </Typography>
                    <Typography
                      key={"totalCostIncludingVAT" + municipalityData.id}
                    >
                      {totals.totalCost > 0
                        ? (totals.totalCost * 1.255).toFixed(2) + // Updated from 1.24 to 1.255
                          " " +
                          t("code." + currency.name) +
                          " " +
                          t("muniReport.includingVAT")
                        : "-"}
                    </Typography>
                    <Typography>
                      {t("muniReport.costRate")}:{" "}
                      {(
                        (totals.totalCost / totals.totalInvoicing) *
                        100
                      ).toFixed(2) + "%"}
                    </Typography>
                  </Stack>
                }
              />
              <ListItemText
                primary={`${t("muniReport.InvoicingPerPaymentTerm")}`}
                secondary={
                  <Stack direction="column" spacing={0}>
                    {Object.keys(totalInvoicingPerPaymentTerm).map((key) => (
                      <Typography key={"invoicingPerPaymentTerm" + key}>
                        {key === "Undefined"
                          ? t("muniReport.NoPaymentTerm")
                          : key}
                        :{" "}
                        {getInvoicingAmountAndAmountWithVAT(
                          totalInvoicingPerPaymentTerm[key]
                        )}
                      </Typography>
                    ))}
                  </Stack>
                }
              />
            </ListItem>
          </List>
        </>
      )}

      {municipalityData.map((municipality) => (
        <Accordion key={municipality.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ background: "#f0f0f0" }}
          >
            {/* <Tooltip
              title={`${t("muniReport.openClub")}: ${municipality.name}`}
              placement="top"
            >
              <Button
                variant=""
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  openMunicipality(municipality.id);
                }}
                sx={{ ml: 0 }} // Adjust margin as needed
              >
                <OpenIcon />
              </Button>
            </Tooltip> */}
            <ListItem key={"header1" + municipality.id}>
              <ListItemText
                primary={municipality.name}
                secondary={
                  t("muniReport.numberOfClubs") +
                  ": " +
                  municipality.clubs.length
                }
              />
            </ListItem>
            <ListItem key={"header1.1" + municipality.id}>
              <ListItemText
                primary={`${
                  municipality.paymentTerm
                    ? municipality.paymentTerm +
                      " " +
                      t("muniReport.paymentTermDays")
                    : ""
                }`}
              />
            </ListItem>

            {/* round totalIcome and totalCost to 2 decimals */}
            <ListItem key={"header2" + municipality.id}>
              <ListItemText
                primary={`${t("muniReport.totalIncome")}: ${
                  municipality.totalIncome
                    ? municipality.totalIncome.toFixed(2)
                    : "-"
                } ${t("code." + currency.name)}`}
                secondary={`${t(
                  "muniReport.totalCost"
                )}: ${municipality.totalCost?.toFixed(2)} ${t(
                  "code." + currency.name
                )}`}
              />
            </ListItem>

            <ListItem key={"header3" + municipality.id}>
              <Paper
                key={"header3.1" + municipality.id}
                sx={{ minWidth: 120, verticalAlign: "middle" }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 14,
                    minHeight: "30px",
                    paddingTop: "1em",
                    paddingBottom: "1em",
                    mb: 0,
                  }}
                  color={getInvoicingStatusColor(municipality.invoicingStatus)}
                  backgroundColor={getInvoicingStatusBackgroundColor(
                    municipality.invoicingStatus
                  )}
                  gutterBottom
                >
                  {t(
                    "muniReport.summaryStatus." + municipality.invoicingStatus
                  )}
                </Typography>
              </Paper>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails>
            {municipality.clubs.map((club) => (
              <Stack direction="row" key={"clubstack" + club.id}>
                <Tooltip
                  title={`${t("muniReport.openClub")}: ${club.longName}`}
                  placement="top"
                >
                  <Button
                    variant=""
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      openClub(club.id);
                    }}
                    sx={{ ml: 0 }} // Adjust margin as needed
                  >
                    <OpenIcon />
                  </Button>
                </Tooltip>
                <ListItem key={"club" + club.id}>
                  <ListItemText
                    primary={club.longName}
                    secondary={`${t("muniReport.numberOfLessons")}: ${
                      club.numberOfLessons
                    }`}
                    numberOfLessons
                  />
                </ListItem>
                <ListItem key={"club2" + club.id}>
                  <ListItemText
                    primary={`${t("muniReport.income")}: ${
                      club.totalIncome ? club.totalIncome.toFixed(2) : "-"
                    } ${t("code." + currency.name)}`}
                    secondary={`${t(
                      "muniReport.cost"
                    )}: ${club.totalCost?.toFixed(2)} ${t(
                      "code." + currency.name
                    )}`}
                  />
                </ListItem>
                <ListItem key={"club3" + club.id}>
                  <Paper sx={{ minWidth: 120, verticalAlign: "middle" }}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: 14,
                        minHeight: "30px",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        mb: 0,
                      }}
                      color={getInvoicingStatusColor(club.invoicingStatus)}
                      backgroundColor={getInvoicingStatusBackgroundColor(
                        club.invoicingStatus
                      )}
                      gutterBottom
                    >
                      {t("muniReport.summaryStatus." + club.invoicingStatus)}
                    </Typography>
                  </Paper>
                </ListItem>
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default MuniReportClubs;
