import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CakeIcon from "@mui/icons-material/Cake";
import { useTranslation, Trans } from "react-i18next";
import jwtDecode from "jwt-decode";

export default function FinishSlotSelection({ token }) {
  const { t } = useTranslation();

  const dayOfWeek = (day) => {
    //A simple function to get the full string of the day
    if (day === "mon") return t("weekday.monday");
    if (day === "tue") return t("weekday.tuesday");
    if (day === "wed") return t("weekday.wednesday");
    if (day === "thu") return t("weekday.thursday");
    if (day === "fri") return t("weekday.friday");
    if (day === "sat") return t("weekday.saturday");
    if (day === "sun") return t("weekday.sunday");
  };
  const styleHtml = () => {
    console.log(document.getElementsByTagName("html")[0].style.overflow);
    document.getElementsByTagName("html")[0].style.overflow = "auto";
  };
  return (
    <>
      {styleHtml()}
      <br></br>

      <p>
        {token.slotSKUClubId
          ? t("slotSelection.steps.thirdStep.confirmText_pre")
          : t("slotSelection.steps.thirdStep.confirmText")}
      </p>
      <p>
        {token.slotSKUClubId
          ? t("slotSelection.steps.thirdStep.confirmText2_pre")
          : t("slotSelection.steps.thirdStep.confirmText2")}
      </p>
      <p>
        {token.slotSKUClubId
          ? t("slotSelection.steps.thirdStep.confirmText3_pre")
          : t("slotSelection.steps.thirdStep.confirmText3")}
      </p>
      <p>
        <strong>{t("slotSelection.steps.thirdStep.gamerTitle")}</strong>
      </p>
      <Card
        sx={{ minWidth: 225, width: 225, height: 100, textAlign: "left" }}
        className="gamer"
      >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {(
              <>
                <CakeIcon fontSize="small" />{" "}
                <span className="date-gamer-slot">
                  {JSON.parse(sessionStorage.getItem("gamer")).birthdate}
                </span>
              </>
            ) || "Gamer Data"}
          </Typography>
          <Typography variant="h5" component="div">
            {JSON.parse(sessionStorage.getItem("gamer")).firstName}
          </Typography>
          <Typography variant="body2">
            {JSON.parse(sessionStorage.getItem("gamer")).lastName}
          </Typography>
        </CardContent>
      </Card>

      {token.plan.seatCount > 0 && !token.slotSKUClubId && (
        <p>
          <strong>{t("slotSelection.steps.thirdStep.slotTitle")}</strong>
        </p>
      )}
      <div className="container-slot">
        {JSON.parse(sessionStorage.getItem("slots")).map((slot) => (
          <ul className="slot-info">
            <li>
              {" "}
              {slot.name} / {dayOfWeek(slot.meetings[0].weekday)} /{" "}
              {slot.meetings[0].startTime.split(":")[0] +
                ":" +
                slot.meetings[0].startTime.split(":")[1]}{" "}
              -{" "}
              {slot.meetings[0].endTime.split(":")[0] +
                ":" +
                slot.meetings[0].endTime.split(":")[1]}{" "}
            </li>
          </ul>
        ))}
      </div>

      {token.slotSKUClubId && (
        <div>
          <p>
            <strong>{t("slotSelection.steps.thirdStep.slotTitle_pre")}</strong>
          </p>
          <div className="container-slot">
            {token.clubs.map((slot) => (
              <ul className="slot-info">
                {slot.meetings.length > 0 && (
                  <li style={{ listStyleType: "none" }}>
                    {" "}
                    {token.plan.name} / {dayOfWeek(slot.meetings[0].weekday)}{" "}
                    {slot.meetings[0].startTime.split(":")[0] +
                      ":" +
                      slot.meetings[0].startTime.split(":")[1]}{" "}
                    -{" "}
                    {slot.meetings[0].endTime.split(":")[0] +
                      ":" +
                      slot.meetings[0].endTime.split(":")[1]}{" "}
                  </li>
                )}
                {slot.meetings.length === 0 && <li>{token.plan.name}</li>}
              </ul>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
